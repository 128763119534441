<template>
  <div class="chat-item" :class="[displayLeftOrRight ? 'chat-item-right' : 'chat-item-left']">
    <div class="chat-item-user-img d-flex align-self-start">
      <img v-if="message.author_photo" :data-src="formatProfilePhoto(message.author_photo)" alt="User"/>
      <div v-else class="avatar-fallback d-flex justify-content-center align-items-center round p-3">
        <i class="fa-solid fa-user text-light"/>
      </div>
    </div>
    <div class="chat-item-content container px-1">
      <div class="chat-item-content-inner">
        <div class="msg col-12 col-md-10 pt-0">
          <div class="holder d-inline-flex flex-column" :class="displayAdminDispute">
            <p v-if="message.body && !message.repeat" :class="[displayLeftOrRight ? '' : 'message-inbound']"
               class="textWrap prewrap" v-text="message.body"/>
            <p v-bind:class="{'border-top mt-1 pt-1 text-break' : (message.body !== null && !message.repeat)}"
               v-if="message.attachment_hash !== null ">
              <b-spinner v-if="downloading" small/>
              <a v-else class="d-flex align-items-center text-break"
                 v-on:click="lookup(message.attachment_hash, message.attachment_filename)" target="_blank"
                 rel="noopener noreferrer">
                <i class="fa-solid fa-paperclip mr-1"/>
                {{ message.attachment_filename }}
              </a>
            </p>
          </div>
        </div>
        <span class="time-and-date px-3 mx-2 mt-0">
          {{ longerThenWeek ? date(message.date_created) : moment(message.date_created).fromNow() }}
          <span v-if="message.author_username === currentUser" class="ml-1">
              <span v-if="message.recipient_read === 1" class="double-tick text-primary">
                <i class="fa-solid fa-check" aria-hidden="true"/>
                <i class="fa-solid fa-check" aria-hidden="true"/>
              </span>
              <i v-else class="fa-solid fa-check text-secondary" aria-hidden="true"/>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../../helper'
import ApiService from '../../apiService'

export default {
  name: 'ChatItem',
  props: {
    message: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    activeMessage: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    messageThreadHash: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    disputePositionData: {
      type: [Object, Boolean],
      required: false,
      default () {
        return false
      }
    },
    currentUser: String
  },
  data () {
    return {
      intervalTimer: null,
      longerThenWeek: false,
      downloading: false
    }
  },
  mounted () {
    this.doMoment()
  },
  methods: {
    doMoment () {
      const duration = this.moment.duration(this.moment().diff(this.moment(this.message.date_created)))
      if (duration.days() > 7 || duration.months() >= 1 || duration.years() >= 1) {
        this.longerThenWeek = true
      } else if (duration.days() < 1) {
        let timer = 30000
        if (duration.hours() >= 1) {
          timer = 1800000
        }
        this.intervalTimer = setInterval(() => {
          this.$forceUpdate()
        }, timer)
      }
    },
    lookup (hash, filename) {
      this.downloading = true
      ApiService.getMessageAttachment(hash).then(response => {
        this.forceDownload(response.data.data, filename)
        this.downloading = false
      })
    },
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    forceDownload (href, filename) {
      let anchor = document.createElement('a')
      anchor.href = href

      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
        document.location = anchor.href
      } else {
        anchor.target = '_blank'
        anchor.download = filename
        document.body.appendChild(anchor)
        anchor.click()
        anchor.remove()
      }
    },
    formatProfilePhoto (imgUrl) {
      return helper.formatCloudinaryUrl(imgUrl, 'profile')
    }
  },
  computed: {
    displayLeftOrRight () {
      if (this.disputePositionData !== false) {
        return this.disputePositionData.author !== this.message.author_username
      } else if (this.message.seller_username === null) {
        return this.message.author_username === this.currentUser
      } else if (this.currentUser === this.message.seller_username) {
        return this.message.author_username === this.message.seller_username
      } else {
        return this.message.author_username !== this.message.seller_username
      }
    },
    displayAdminDispute () {
      if (this.disputePositionData !== false && this.disputePositionData.author !== this.message.author_username) {
        return 'admin-colour'
      } else if (this.disputePositionData !== false && this.disputePositionData.buyerColour === true && this.disputePositionData.author === this.message.author_username) {
        return 'seller-colour'
      }
      return ''
    }
  },
  destroyed () {
    clearInterval(this.intervalTimer)
  }
}
</script>
<style lang="scss" scoped>
.chat-item {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin-bottom: 15px;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  text-align: left;
  font-size: 12px;

  .msg-img {
    margin-bottom: 3px;

    img {
      max-width: 100%;
      width: 100%;
      border-radius: 1.3em;
      border: solid 1px #f1f0f0;
    }
  }

  .chat-item-content {
    max-width: 55%;
  }

  .time-and-date {
    display: block;
    font-size: 10px;
    color: #636c72;
    margin: 8px 0 0 12px;
  }

  .actions {
    padding: 6px 12px;

    .btn {
      font-size: .75rem;
    }
  }

  .msg {
    color: black;
    margin: 1px 0;
    clear: left;
    border: 1.3em;
    word-wrap: break-word;
    padding: 6px 12px;
    border-radius: 0.5em 0.5em 0.5em 0.1em;
    width: auto;

    p {
      margin: 0;
      padding-bottom: 0;
      font-size: 13px;
    }
  }

  .chat-item-user-img img, .avatar-fallback {
    min-height: 42px;
    min-width: 42px;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    overflow: hidden;
    padding: 0;
    box-shadow: $message-ui-profile-shadow;
  }

  .chat-item-content {
    max-width: none;
  }

  &.chat-item-right {
    text-align: right;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;

    .msg {
      clear: right;
      display: inline-block;
      border-radius: 0.5em 0.5em 0.1em 0.5em;
      width: auto;
      color: #fff;

      .holder {
        background-color: $brand-sky;
        color: white;
        box-shadow: $message-ui-message-shadow;
        border-radius: 5px;
        padding: 0.75rem;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: 8px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 10px solid $brand-sky;
        }

        a {
          color: white;
        }
      }
    }
  }

  &.chat-item-left {
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;

    .msg {
      .holder {
        background-color: $brand-denim;
        color: white;
        box-shadow: $message-ui-message-shadow;
        border-radius: 5px;
        padding: 0.75rem;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          left: 8px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-right: 10px solid $brand-denim;
        }

        a {
          color: white;
        }
      }
    }
  }

  .object-user-img {
    -webkit-align-self: flex-end;
    align-self: flex-end;

    img {
      max-height: 60px;
      max-width: 60px;
      border-radius: 50%;
      overflow: hidden;
      display: block;
      padding: 0;
    }
  }

  .object-user {
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;

    .chat-item-content {
      margin-left: 10px;
    }

    .msg {
      background-color: #f1f0f0;
    }
  }

  .object-me {
    text-align: right;
    -webkit-flex-direction: row-reverse;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;

    .msg {
      clear: right;
      display: inline-block;
      border-radius: 0.5em 0.5em 0.1em 0.5em;
      width: auto;
      background: #2ECC71;
      color: #fff;
    }
  }

  .textWrap {
    overflow-wrap: anywhere;
    hyphens: auto;
    width: auto;
  }

  .prewrap {
    white-space: pre-wrap;
  }
}

.double-tick > i:nth-of-type(2) {
  margin-left: -6px;
}

</style>
