<template>
  <div class="container-fluid">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
      { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
      { 'name': 'Job Boards', 'link': $router.resolve({ name: 'admin_job_landing' }).href },
      { 'name': 'List' }]"/>

    <div class="main-container">
      <h1 class="mb-2 font-weight-bold">Job Boards - Listing</h1>

      <div class="bg-white border rounded p-4 mb-5">
        <div class="d-flex justify-content-center">

          <div class="input-group mr-4">
            <input type="text" class="form-control" placeholder="Enter username" v-model="searchTerm">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="getJobItems(1)">Search</button>
            </div>
          </div>

          <b-dropdown :text="'Filter Status: ' + $t('job.status')[this.status]" class="mr-2">
            <b-dropdown-item @click="handleFilter(-1)">All</b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item @click="handleFilter(0)">Draft</b-dropdown-item>
            <b-dropdown-item @click="handleFilter(1)">Waiting approval</b-dropdown-item>
            <b-dropdown-item @click="handleFilter(2)">Live</b-dropdown-item>
            <b-dropdown-item @click="handleFilter(3)">Completed</b-dropdown-item>
            <b-dropdown-item @click="handleFilter(4)">Expired</b-dropdown-item>
            <b-dropdown-item @click="handleFilter(5)">Declined</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div v-if="loaded">
        <div v-if="items.length === 0">
          <i class="fa-solid fa-5x fa-person-circle-exclamation mb-3"></i>
          <p>Looks like their no jobs results found</p>
        </div>
        <div v-else class="bg-white p-3 rounded">
          <div class="box-title border-bottom mb-3">
            <span class="small">{{ paginationInfo }}</span>
          </div>
          <table class="table table-striped table-bordered add-manage-table bg-white">
            <thead>
            <tr>
              <th>Username</th>
              <th>Title</th>
              <th>Offer</th>
              <th>Status</th>
              <th>Date Created</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.user.username }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.job_offer.length }}</td>
              <td>{{ $t('job.status')[item.status] }}</td>
              <td>{{ displayDatetime(item.date_created) }}</td>
              <td class="text-right">
                <b-dropdown text="Select an action" variant="outline-dark">
                  <b-dropdown-item @click="openPreview(item.hash, item.title)">Preview</b-dropdown-item>
                  <b-dropdown-item @click="getJobNotes(item.hash, item.title)">View notes</b-dropdown-item>
                  <b-dropdown-item :disabled="item.job_offer.length < 1" @click="getJobOffers(item.hash, item.title)">View offers</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item :disabled="item.status !== 2" @click="handleDeactivated(index)">Deactivated</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-else>
        <div class="mx-auto my-5">
          <b-spinner large label="Large Spinner"></b-spinner>
          <div class="mt-2">getting jobs...</div>
        </div>
      </template>

      <div class="d-flex justify-content-center">
        <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                    v-on:paginationUpdate="getJobItems"/>
      </div>
    </div>

    <admin-job-offer-modal :job="job" @job-offer-audit-modal="openAuditModel"/>
    <admin-job-offer-audit-modal :job="job" />
    <admin-job-notes-modal :job="job" />
    <admin-job-preview-modal :job="job" />

  </div>
</template>

<script>
import Pagination from '../../../components/Global/Pagination'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import ApiService from '../../../apiService'
import AdminJobOfferModal from '../../../components/Admin/Job/AdminJobOfferModal'
import AdminJobOfferAuditModal from '../../../components/Admin/Job/AdminJobOfferAuditModal'
import AdminJobNotesModal from '../../../components/Admin/Job/AdminJobNotesModal'
import AdminJobPreviewModal from '../../../components/Admin/Job/AdminJobPreviewModal'

export default {
  name: 'AdminJobList',
  components: {
    AdminJobPreviewModal,
    AdminJobNotesModal,
    AdminJobOfferAuditModal,
    AdminJobOfferModal,
    Pagination,
    Breadcrumb
  },
  data () {
    return {
      status: -1,
      loaded: false,
      searchTerm: '',
      items: [],
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      },
      notes: [],
      job: {
        title: '',
        hash: '',
        audits: []
      }
    }
  },
  async created () {
    await this.getJobItems()
  },
  methods: {
    handleFilter (value) {
      this.status = value
      this.getJobItems(1)
    },
    async getJobItems (page) {
      this.loaded = false
      let paramsData = {
        'status': this.status,
        'offers': 1
      }
      if (this.searchTerm !== '') {
        paramsData.term = this.searchTerm
      }

      await ApiService.getAdminJobListing(paramsData, page).then((resp) => {
        this.items = resp.data.data.results
        this.pagination = resp.data.data.pagination
      }).finally(() => {
        this.loaded = true
      })
    },
    getJobNotes (hash, title) {
      this.job.hash = hash
      this.job.title = title
      this.$bvModal.show('job-notes-modal')
    },
    getJobOffers (hash, title) {
      this.job.hash = hash
      this.job.title = title
      this.$bvModal.show('job-offer-modal')
    },
    openAuditModel (data) {
      this.job.audits = data
      this.$bvModal.show('job-offer-audit-modal')
    },
    openPreview (hash, title) {
      this.job.hash = hash
      this.job.title = title
      this.$bvModal.show('job-preview-modal')
    },
    handleDeactivated (index) {
      const item = this.items[index]

      this.$swal({
        title: 'Deactivated ' + item.title,
        text: 'please note that the reason will be shown to the owner of the job',
        type: 'warning',
        showLoaderOnConfirm: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Deactivate job',
        showCloseButton: true,
        input: 'textarea',
        inputPlaceholder: 'Please type your reason here for deactivating the job',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a reason'
          }
        },
        preConfirm: (result) => {
          if (result !== '') {
            return ApiService.postJobResponseReview(item.hash, { 'action': 0, 'reason': result }).then(() => {}).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
              return false
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$swal({
            title: 'Success',
            text: 'The job has been deactivated',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            item.status = 0
          })
        }
      })
    },
    displayDatetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  },
  computed: {
    paginationInfo () {
      const currentTotalPosition = this.pagination.per_page * (this.pagination.page - 1)
      return this.$t('showing', { from: currentTotalPosition + 1, to: this.items.length + currentTotalPosition, total: this.pagination.total })
    }
  }
}
</script>
